import request from "@/Rest";
import { USER_URLS } from "@/Rest/api/urls";

const containsKey = (obj, key) => Object.keys(obj).includes(key);

export async function login({ commit }, { email, password }) {
  const credentials = email + ":" + password;
  const credentialsB64 = btoa(credentials);
  let to_Response = {};
  commit("cleanErrors");
  await request
    .POST(
      USER_URLS.login,
      {},
      {
        headers: {
          Authorization: "Basic " + credentialsB64,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (!response.status === 200) {
        return;
      }
      if (!containsKey(response.data, "access")) {
        return;
      }
      const code = response.data["user_data"]["code"];
      if (!code && response.data["user_data"]["type"] === "Registrarion_code") {
        to_Response["code_error"] = true;
        return;
      }
      commit("setAccessToken", response.data["access"]);
      commit("setRefreshToken", response.data["refresh"]);
      commit("setUserData", response.data["user_data"]);
      to_Response.isLogged = true;
      to_Response.email = response.data["user_data"].email;
      to_Response.isFreeTrial = response.data["user_data"].free_trial;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        commit(
          "registerError",
          "Wrong username or password. Please try again."
        );
      } else {
        commit(
          "registerError",
          "An error has occurred. Please reload the page again."
        );
        commit("logout");
      }
      to_Response.isLogged = false;
    });
  return to_Response;
}

export async function logout({ commit }) {
  let to_Response = {};
  commit("cleanErrors");
  commit("logout");
  return to_Response;
}

export async function refreshToken({ commit }, refreshToken) {
  commit("cleanErrors");
  let to_Response;
  if (refreshToken) {
    await request
      .POST(USER_URLS.refreshToken, { refresh: refreshToken }, {})
      .then((response) => {
        commit("setAccessToken", response.data["access"]);
        to_Response = response.data;
      })
      .catch(function (err) {
        commit("registerError", err);
      });
  } else {
    to_Response = "Invalid refresh token";
  }
  return to_Response;
}

export async function registerNewUser({ commit }, signUpData) {
  commit("cleanErrors");
  const requestPayload = {
    first_name: signUpData.firstName,
    last_name: signUpData.lastName,
    from_web: signUpData.fromWeb,
    registration_code: signUpData.registrationCode,
    number_dependents: signUpData.dependents,
    phone_number: signUpData.phoneNumber,
    is_free_trial: signUpData.isFreeTrial,
    timezone: signUpData.timeZone,
  };

  const credentials = signUpData.email + ":" + signUpData.password;
  const credentialsB64 = btoa(credentials);

  try {
    const response = await request.POST(USER_URLS.register, requestPayload, {
      headers: {
        Authorization: `Basic ${credentialsB64}`,
        "Content-Type": "application/json",
      },
    });

    commit("setUserName", response.data["first_name"]);
    commit("setUserId", response.data["user_id"]);
    commit("setAccessToken", response.data["access"]);
    commit("setRefreshToken", response.data["refresh"]);
    commit("setFullName", {
      firstName: response.data["first_name"],
      lastName: response.data["last_name"],
    });
    commit("setEmail", response.data["email"]);
    commit("setPhoneNumber", signUpData.phoneNumber);
    commit("setGuardianOptIn", true);
  } catch (err) {
    let errorMessage;
    const { 
      email, 
      non_field_errors, 
      registration_code, 
      phone_number
    } = err.response.data;

    if (email) {
      errorMessage = "Email already exists. Please try again.";
    } else if (non_field_errors) {
      errorMessage = "Password too common. Please try again.";
    } else if (registration_code) {
      errorMessage = "Registration code is invalid or inactive";
    } else if (phone_number) {
      errorMessage = "Invalid phone number";
    } else {
      errorMessage = "Unexpected error, Please try again.";
    }

    commit("registerError", errorMessage);
    throw new Error(errorMessage);
  }
}

export async function totpDevice({ commit }) {
  let to_Response = {};

  await request
    .GET(USER_URLS.createTOTPDevice, {})
    .then((response) => {
      to_Response.totpLink = response.data;
      commit("setTOTPLink", response.data);
    })
    .catch((err) => {
      commit("registerError", err);
    });

  return to_Response;
}

export async function verifyTOTP({ commit }, { totpCode }) {
  let to_Response;
  to_Response = false;
  await request
    .POST(USER_URLS.verifyTOTPDevice, {
      token: totpCode,
    })
    .then(async (response) => {
      if (response.status === 200) {
        commit("setUserIsLogged", true);

        to_Response = true;
      }
    })
    .catch((err) => {
      commit("registerError", err);
      to_Response = false;
    });

  return to_Response;
}

export async function createRecoveryCodes({ commit }) {
  let to_Response = {};
  await request
    .GET(USER_URLS.recoveryCode)
    .then((response) => {
      if (response.status === 201) {
        commit("setTOTPRecovery", response.data);
        to_Response.codes = response.data;
      }
    })
    .catch((err) => {
      commit("registerError", err);
    });

  return to_Response;
}

export async function verifyRecoveryCode({ commit }, recoveryToken) {
  let to_Response = {};
  await request
    .POST(USER_URLS.verifyRecoveryCode, {
      token: recoveryToken,
    })
    .then((response) => {
      if (response.status === 200) {
        commit("setUserIsLogged", true);
        to_Response = true;
      }
    })
    .catch((err) => {
      commit("registerError", err);
      to_Response = false;
    });
  return to_Response;
}

export async function sendRegistrationCode({ commit }, { code, id }) {
  let to_Response = { success: false };
  const payload = {
    code,
    type: "Registrarion_code",
  };
  await request
    .PATCH(USER_URLS.sendRegistrationCode(id), payload)
    .then((response) => {
      if (response.status === 200) {
        to_Response["success"] = true;
        commit("setUserDependents", response.data["number_dependents"]);
      }
    })
    .catch((err) => {
      commit("registerError", err);
    });
  return to_Response;
}

export async function createDependent(
  { commit },
  {
    firstName,
    lastName,
    username,
    ageRange,
    brainHemisphere,
    email,
    trainingPurpose,
    sport,
    dateBirth,
    gender,
    phoneNumber,
    acceptedOptIn,
    fromAddPlayer
  }
) {
  const payload = {
    first_name: firstName,
    last_name: lastName,
    username,
    age_range: ageRange,
    brain_hemisphere: brainHemisphere,
    email,
    training_purpose: trainingPurpose,
    sport,
    date_birth: dateBirth,
    gender,
    phone_number: phoneNumber,
    opt_in_status: acceptedOptIn,
    from_add_player: fromAddPlayer
  };
  try {
    await request.POST(USER_URLS.createDependent, payload);
  } catch (err) {
    commit("registerError", err);

    const { phone_number } = err.response.data

    if (err.response.status === 403) {
      throw new Error("You have already registered all your profiles!");
    } else if (phone_number) {
      throw new Error("Invalid phone number");
    } else if (err.response.status >= 500) {
      throw new Error("Error server side, please try again later");
    }

    throw new Error(err.response.data[0]);
  }
}
