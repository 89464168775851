import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export function getUsername(state) {
  state.userData["name"] = cookies.get("userName");
  return state.userData["name"];
}

export function getUserId(state) {
  state.userData["userId"] = cookies.get("userId");
  return state.userData["userId"];
}

export function getAccessToken(state) {
  state.accessToken = cookies.get("accessToken");
  return state.accessToken;
}

export function getRefreshToken(state) {
  state.refreshToken = cookies.get("refreshToken");
  return state.refreshToken;
}

export function getUserIsLogged(state) {
  state.isLogged = cookies.get("userIsLogged") === "true";
  return state.isLogged;
}

export function getIsFreeTrialExpired(state) {
  state.isFreeTrialExpired = cookies.get("isFreeTrialExpired") === "true";
  return state.isFreeTrialExpired;
}

export function getUserData(state) {
  state.userData["id"] = cookies.get("userId");
  state.userData["name"] = cookies.get("userName");
  state.userData["role"] = cookies.get("userRole");
  state.userData["phoneNumber"] = cookies.get("phoneNumber");
  state.userData["guardianOptIn"] = cookies.get("guardianOptIn");
  state.userData["mfaActivated"] = cookies.get("mfaActivated") === "true";
  state.userData["last_login"] = cookies.get("last_login");
  state.userData["dependents"] = cookies.get("dependents");
  state.userData["type"] = cookies.get("type");
  state.userData["location"] = cookies.get("location");
  state.userData["email"] = cookies.get("email");
  state.userData["firstName"] = cookies.get("firstName");
  state.userData["lastName"] = cookies.get("lastName");

  return state.userData;
}

export function getUserDependents(state) {
  return state.dependents;
}

export function getMFA(state) {
  return state.mfa;
}
