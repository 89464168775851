import { createApp } from "vue";
import App from "./App.vue";
import VueCookies from "vue3-cookies";

import router from "./router";
import store from "./store";

import { axios, VueAxios } from "./plugins/vue-axios";
import interceptor from "./plugins/axios_interceptor";
import { createGtm } from '@gtm-support/vue-gtm';

import Toast from "vue-toastification";

import "./assets/tailwind.css";
import "vue-toastification/dist/index.css";

createApp(App)
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(interceptor)
  .use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None",
  })
  .use(Toast)
  .use(createGtm({
    id: process.env.VUE_APP_GTM_CONTAINER_ID,
  }))
  .mount("#app");
